import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/client-and-property-information/:id',
    name: 'Client And Property Information',
    meta: {
      hide: true,
    },
    component: () => import('../views/ClientAndPropertyInformationForm.vue'),
  },
  {
    path: '/letter-of-offer/:id',
    name: 'Letter Of Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/LetterOfOfferForm.vue'),
  },
  // About Us
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/our-people',
    name: 'Our People',
    component: () => import('../views/OurPeople.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    path: '/why-choose-us',
    name: 'Why Choose Us',
    component: () => import('../views/WhyChooseUs.vue'),
  },
  {
    path: '/our-core-values',
    name: 'Our Core Values',
    component: () => import('../views/OurCoreValues.vue'),
  },
  {
    path: '/our-purpose',
    name: 'Our Purpose',
    component: () => import('../views/OurPurpose.vue'),
  },
  {
    path: '/our-core-values-and-our-purpose',
    name: 'Our Core Value & Our Purpose',
    component: () => import('../views/OurCoreValueOurPurpose.vue'),
  },
  {
    path: '/make-explore-your-home',
    name: 'Make Explore Property Your Home',
    component: () => import('../views/MakeExploreYourHome.vue'),
  },
  {
    path: '/in-the-community',
    name: 'In The Community',
    component: () => import('../views/Blog.vue'),
  },
  // Sell
  {
    path: '/why-choose-us',
    name: 'Why Choose Us',
    component: () => import('../views/WhyChooseUs.vue'),
  },
  {
    path: '/forms/sales-appraisal/',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  // Buy
  {
    path: '/inspection-times',
    name: 'Inspection Times',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/calculators',
    name: 'Calculators',
    meta: {
      hide: true,
    },
    component: () => import('../views/Calculators.vue'),
  },
  // Rent
  {
    path: '/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/rental-application',
    name: 'Rental Application',
    component: () => import('../views/RentalApplicationForm.vue'),
  },
  // Useful Tools
  {
    path: '/complete-guide-to-selling-a-property',
    name: 'Selling A Property',
    component: () => import('../views/CompleteGuideToSellingAProperty.vue'),
  },
  {
    path: '/complete-guide-to-buying-a-property',
    name: 'Buying A Property',
    component: () => import('../views/CompleteGuideToBuyingAProperty.vue'),
  },
  {
    path: '/resources-for-landlords',
    name: 'Resources For Landlords',
    component: () => import('../views/ResourcesForLandlords.vue'),
  },
  {
    path: '/resources-for-tenants',
    name: 'Resources For Tenants',
    component: () => import('../views/ResourcesForTenants.vue'),
  },
  {
    path: '/forms/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  // Resources for Tenants
  {
    path: '/tenant-troubleshooting-guide',
    name: 'Tenant Troubleshooting Guide',
    component: () => import('../views/TenantTroubleshootGuide.vue'),
  },
  // Core Logic Plugin
  {
    path: '/property-report',
    name: 'Property Report',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReport.vue'),
  },
  {
    path: '/property-report-preview',
    name: 'Property Report Preview',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReportPreview.vue'),
  },
  {
    path: '/property-report-digital-appraisal',
    name: 'Property Report Digital Appraisal',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReportDigitalAppraisal.vue'),
  },
  {
    path: '/rental-report',
    name: 'Rental Report',
    meta: {
      hide: true,
    },
    component: () => import('../views/RentalReport.vue'),
  },
  {
    path: '/rental-report-preview',
    name: 'Rental Report Preview',
    meta: {
      hide: true,
    },
    component: () => import('../views/RentalReportPreview.vue'),
  },
  {
    path: '/rental-report-digital-appraisal',
    name: 'Rental Report Digital Appraisal',
    meta: {
      hide: true,
    },
    component: () => import('../views/RentalReportDigitalAppraisal.vue'),
  },
  {
    path: '/suburb-report',
    name: 'Suburb Report',
    meta: {
      hide: true,
    },
    component: () => import('../views/SuburbReport.vue'),
  },
  {
    path: '/suburb-report-detail',
    name: 'Suburb Report Detail',
    meta: {
      hide: true,
    },
    component: () => import('../views/SuburbReportDetail.vue'),
  },
  // Switch to us
  {
    path: '/switch',
    name: 'Switch to Us',
    component: () => import('../views/SwitchToUs.vue'),
  },
  // Centralised Listing Page
  {
    // Listing search page (with the map)
    path: '/listings/:type(for-sale|land-for-sale|for-rent|commercial|sold|leased)',
    name: 'Listings',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/Listings.vue'),
  },
  {
    // Dynamic Pages
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/people/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/people/:id/properties/sold',
    name: 'Profile Properties Sold',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/people/:id/properties',
    name: 'Profile Properties',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/appraisals/:name/:id',
    name: 'Appraisal Details',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/ViewAppraisal.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog Details',
    component: () => import('../views/BlogDetails.vue'),
  },
  // Other
  {
    path: '/video-appraisal-request',
    name: 'Video Appraisal',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/ViewAppraisalRequest.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    meta: {
      hide: true,
    },
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                name: slug.Slug,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/people/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Office':
              API.Offices.Get(slug.Id).then((office) => {
                next({
                  path: `/offices/${office.OfficeSlug}`,
                  replace: true,
                });
              }).catch(() => {
                next();
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
